import { render, staticRenderFns } from "./PropertyTypesList.vue?vue&type=template&id=7b9d1604&scoped=true&"
import script from "./PropertyTypesList.vue?vue&type=script&lang=js&"
export * from "./PropertyTypesList.vue?vue&type=script&lang=js&"
import style0 from "./PropertyTypesList.vue?vue&type=style&index=0&id=7b9d1604&lang=scss&scoped=true&"
import style1 from "./PropertyTypesList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b9d1604",
  null
  
)

export default component.exports