<template>
  <b-modal
    :visible="sidebarStatus"
    hide-header
    hide-footer
    @change="(val) => $emit('updateSidebarStatus', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t('propertyTypesModel.title') }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Title EN -->
          <validation-provider
            #default="validationContext"
            name="Title - English"
            rules="required"
          >
            <b-form-group
              :label="$t('propertyTypesModel.titleEnglish')"
              label-for="Title - English"
            >
              <b-form-input
                id="Title - English"
                v-model="propertyTypeData.titleEn"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('propertyTypesModel.titleEnglish')"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Title ES -->
          <validation-provider
            #default="validationContext"
            name="Title - Spanish"
            rules="required"
          >
            <b-form-group
              :label="$t('propertyTypesModel.titleSpanish')"
              label-for="Title - Spanish"
            >
              <b-form-input
                id="Title - English"
                v-model="propertyTypeData.titleEs"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('propertyTypesModel.titleSpanish')"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Title SW -->
          <validation-provider
            #default="validationContext"
            name="Title - Swedish"
            rules="required"
          >
            <b-form-group
              :label="$t('propertyTypesModel.titleSwedish')"
              label-for="Title - Swedish"
            >
              <b-form-input
                id="Title - English"
                v-model="propertyTypeData.titleSw"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('propertyTypesModel.titleSwedish')"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <b-spinner v-if="loading" small :label="$t('commons.loading')" />
              <span v-else>{{ $t('buttons.save') }}</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="
                hide()
                resetPropertyTypeData()
              "
            >
              {{ $t('buttons.cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import PropertyTypeModel from '@/models/property-type.model'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { localize } from 'vee-validate'
import i18n from '@/libs/i18n/index'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BSpinner,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    sidebarStatus: {
      type: Boolean,
      required: true,
    },
    editItem: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      hideOnEdit: false,
    }
  },
  watch: {
    editItem(val) {
      this.propertyTypeData = new PropertyTypeModel(val)
    },
    '$i18n.locale': function (newVal, oldVal) {
      localize(newVal)
    },
  },
  computed: {
    loading() {
      return this.$store.state['app-property-types'].loading
    }
  },
  setup(props, { emit }) {
    const blankPropertyTypeData = new PropertyTypeModel()
    const propertyTypeData = ref(blankPropertyTypeData)

    const resetPropertyTypeData = () => {
      propertyTypeData.value = new PropertyTypeModel(blankPropertyTypeData)
    }

    const toast = useToast()

    const onSubmit = () => {
      store
        .dispatch('app-property-types/setPropertyType', propertyTypeData.value)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('toasts.save.success'),
              icon: 'CheckIcon',
              variant: 'success',
            },
          })

          emit('refetch-data')
          emit('updateSidebarStatus', false)
          emit('resetItem')
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('toasts.save.error'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetPropertyTypeData,
    )

    return {
      propertyTypeData,
      onSubmit,
      resetPropertyTypeData,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-property-type-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
